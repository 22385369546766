<template lang="pug">
#home(:class="{'not-desktop': browser.type!=='desktop'}")
  .dev-controls
    ul
      li {{ userFbId }}
      li {{ userName }}
      //- li {{ userPic }}
      //- li
      //-   img(:src="userPic")
      li
        img(:src="userPicBase64")
      li {{ isUserLogin }}
    ul
      li(@click="loginFb") login to FB
      li(@click="createOrGetUser") create or get user
      li(@click="checkFbStatus") checkFbStatus
      li(@click="getUserFbData") getUserFbData
      li(@click="logoutFB") logout FB
      li(@click="getImg") getimg
      li(@click="vote(1)") vote
      li(@click="getVideos") get videos
  video-mask(v-if="showMask")
  VideoFrame(v-if="showPlayVideo" :data="selectedVideo" @close="showPlayVideo=false")

  bottom-banner

  navigator

  mobile-navigator.d-block.d-lg-none(:visible="mobileNavigatorVisible"
    @close:navigator="mobileNavigatorVisible = false")

  main.home-wrapper.position-relative.overflow-hidden.parallax-scene

    section.campaign-section.position-relative(ref="scene")
      page-observer(status="")

      .layer(data-depth="0.2")
        .second-layer.animate__animated.animate__bounceInUp.animation_delay_4
          .hand-hold-beer.position-absolute

      .layer(data-depth="0.3")
        .second-layer.animate__animated.animate__bounceIn.animation_delay_6
          .hash-tag-left.position-absolute
            p.mb-0.position-relative #拿金牌挺中華

      .layer(data-depth="0.4")
        .second-layer.animate__animated.animate__bounceIn.animation_delay_6
          .hash-tag-right.position-absolute
            p.mb-0.position-relative #想贏的舉手

      .layer(data-depth="0.8")
        a.logo-icon.position-absolute(href="https://www.twbeer.com.tw/over18")
        .social-button-group.position-absolute
          a.social-button.facebook(href="https://www.facebook.com/beer.taiwan/" target="_blank")
          a.social-button.instagram(href="https://www.instagram.com/taiwan.beer/?hl=zh-tw" target="_blank")
          button.hamburger-menu.position-relative.d-block.d-lg-none(@click="mobileNavigatorVisible = true")
            span
            span
            span

        .container.position-relative
          .row
            .col-12.parallax-layer(data-depth="0.01")
              tw-title.animate__animated.animate__bounceIn.animation_delay_5
          .row.campaign-wrapper
            decoration-title.animate__animated.animate__fadeInRightBig.animation_delay_2(name="團員大募集")
            .col-12.d-flex.justify-content-center.article-wrapper
              .campaign-block.animate__animated.animate__fadeInRightBig.animation_delay_2
                .loudspeaker
                .fighting \ 應援女神峮峮 /
                article.campaign-article.position-relative
                  p 東奧在即！金牌台啤邀請全台灣人，一起加入最強應援團！
                  p FOLLOW 美秀集團與峮峮，跳出屬於你風格的【 閃閃舞 】， 應援中華隊的同時，有機會拿萬元現金！
                .campaign-button-group
                  a(href="#step") 即 刻 加 入
                  a(href="#instagram") 前 往 投 票
    section#teach.teach-section.pb-5.position-relative

      home-decoration-firework.position-absolute.decoration-firework-left(:index="1")
      home-decoration-thunder.position-absolute.decoration-thunder-right.rellax(data-rellax-speed="3")

      .container.position-relative
        .row
          .col-12
            .teach-block
              .flex-center.flex-column
                h2.dance-title 【閃 閃 舞】教 學
                p.dance-subtitle —  跟著【峮峮x 美秀集團】跳起來  —
              .video-block.flex-center.position-relative(data-aos="zoom-in-down")
                img.position-absolute.decoration-1.star(src="../assets/images/tw-title-star-left.svg" alt="star")
                img.position-absolute.decoration-2.star(src="../assets/images/tw-title-star-right.svg" alt="star")
                img.dance-video(src="../assets/images/teach-video-girl.gif" alt="teach-video-girl")

    section#step.step-section.pb-5.position-relative
      page-observer(status="step")

      home-decoration-firework.position-absolute.decoration-firework-right(:index="2")
      home-decoration-thunder.position-absolute.decoration-thunder-left.purple.rellax(data-rellax-speed="3")
      home-decoration-thunder.position-absolute.decoration-thunder-right.rellax(data-rellax-speed="3")

      .container.position-relative
        .row
          .col-12
            .step-title-block.flex-center.mb-5.mt-2
                decoration-title(name="活動辦法" class="normal")
        
        .row
          .col-12
            .step-block.mb-5.mt-4(data-aos="zoom-in-down")
              p.step-1 錄你的閃閃舞！播放【金光閃閃】副歌部分。（跟著上方教學影片一起跳OR自創你的應援舞步）
              p.step-2 公開分享到【個人IG貼文】或 【FB塗鴉牆】
                br 
                span （擇一上傳，上傳FB者，分享後需複製影片連結，並投稿至下方表單；上傳IG者，完成下方第三步驟，系統會自動抓取）
              p.step-3 加上#想贏的舉手、#拿金牌挺中華隊、#taiwanbeer及tag一位奧運選手的個人帳號 
                br
                span (我要為王子維@wangtzuwei0227 加油!)
        
        .row
          .col-12
            .submit-button-block.d-flex.justify-content-sm-end.justify-content-center.align-items-center(data-aos="zoom-in-right")
              button.submit-button
                a(href="https://forms.gle/pfEhcTkaegjfPbYn8" target="_blank") 投稿表單
          .col-12
            .remind-block.position-relative.mx-auto.mt-3.mb-5(data-aos="zoom-in-left")
              p.mb-0 即日起至8/6完成以上步驟，有機會讓你的應援被選手看見，更有萬元現金和免費啤酒等你拿!
            p.alert-word.mx-auto 本活動至8/6止，將於8/9公布得獎人名單至官方粉絲團
              br 
              | 如貼文中未標示 
              span(style="color: #fbe260; word-wrap:break-word;") #想贏的舉手、#拿金牌挺中華隊
              | ，該影片將不列入本次活動投票。

    cross-banner
      slot
        img.cross-banner-image-1.shake(src="../assets/images/step-boy-group-2.png")

    section#instagram.instagram-section.py-5.position-relative
      page-observer(status="instagram")

      home-decoration-firework.position-absolute.decoration-firework-left(:index="3")
      home-decoration-firework.position-absolute.decoration-firework-right(:index="4")
      home-decoration-thunder.position-absolute.decoration-thunder-left.purple.rellax(data-rellax-speed="3")

      .container
        .row
          .col-12.position-relative
            .flex-center.mb-5
              decoration-title(name="票選影片" class="normal")
            .slogan-block.position-absolute 
              p.slogan.position-relative 動動手指，投票選出你心中的應援王！
        .row.pt-4.pt-sm-2.px-2
          .col-sm-4.pt-5.mt-sm-5.mt-2.px-4.hot-instagram(v-for="(ins, index) in instagramTopThree" :key="index")
            instagram-thumbnail(
              @playVideo="playVideo"
              @vote="vote"
              :data="ins"
              :class="['hot', `ig-top-${index}`]"
              data-aos="zoom-out-up")
          img.pt-5(src="../assets/images/video-decoration-arrow-down.svg")
        .row
          .col-12.pt-5.position-relative
            img.position-absolute.start-0.bottom-0.star(src="../assets/images/tw-title-star-right.svg")
            .video-button-group.d-flex.justify-content-center.justify-content-sm-start.pb-3.position-relative
              img.position-absolute.end-0.bottom-50.star(src="../assets/images/tw-title-star-left.svg")
              button(v-for="({ name, trigger }, index) in videoSortTypes" 
                :key="index" v-html="name" 
                :class="[trigger ? 'active' : '', index === 2 ? 'letter-spacing' : '']" 
                @click="videoSort(index)")
            //- 
            .splide(data-aos="fade-down")
              .splide__track
                ul.splide__list
                  li.splide__slide(v-for="(ins, key) in configInstagramData" :key="key")
                    instagram-thumbnail(
                      @playVideo="playVideo"
                      @vote="vote"
                      v-for="(data, index) in ins" 
                      :key="`ins-${index}`" 
                      :data="data" class="ig-swiper")

    cross-banner.left
      slot
        img.cross-banner-image-2.jump(src="../assets/images/video-group.png" alt="decoration-image")

    section#explanation.explanation-section.py-5.position-relative
      page-observer(status="explanation")

      home-decoration-firework.position-absolute.decoration-firework-left(:index="5")
      home-decoration-firework.position-absolute.decoration-firework-right.d-block.d-sm-none(:index="6")
      home-decoration-thunder.position-absolute.decoration-thunder-left.purple.d-block.d-sm-none.rellax(data-rellax-speed="3")
      home-decoration-thunder.position-absolute.decoration-thunder-right.d-none.d-md.block.rellax(data-rellax-speed="3")
      //- home-decoration-thunder.position-absolute.decoration-thunder-right-2.d-none.d-sm-block.rellax(data-rellax-speed="3")
      //- home-decoration-thunder.position-absolute.decoration-thunder-right-3.d-none.d-sm-block.rellax(data-rellax-speed="3")

      .container.position-relative
        .row
          .col-12
            .flex-center.mb-5.pb-5.position-relative
              decoration-title(name="獎項說明" class="normal") 
              .decoration-dialog.position-absolute 金光閃閃三重抽
        .row
          .col-12
            .step-block(data-aos="zoom-out-down")
              .content.detail-1
                p.step-content-title 凡上傳閃閃舞影片，即可獲得抽獎資格 
                  br.d-md-none
                  | (獎金於活動結束後抽出)
                p.item.d-flex.flex-column.align-items-center.d-sm-block ・ 頭獎：新台幣一萬塊現金獎，共1名 
                  button.download-button.mt-2.mt-md-0 中獎回覆函下載
                p.item.d-flex.flex-column.align-items-center.d-sm-block ・ 貳獎：新台幣五千塊現金獎，共3名
                  button.download-button.mt-2.mt-md-0 中獎回覆函下載
                p.item.d-flex.flex-column.align-items-center.d-sm-block ・ 參獎：新台幣三千塊現金獎，共5名
                  button.download-button.mt-2.mt-md-0 中獎回覆函下載
              .content.detail-2.position-relative
                img.decoration-hold-beer.position-absolute.d-sm-block.d-none(src="../assets/images/hand-hold-beer.svg" alt="tw-beer-hold-beer")
                p.step-content-title 每週抽一名最佳精神獎，上傳者皆有資格抽兩箱金牌台啤!
                p.remind-word.d-flex.flex-column.align-items-center.d-sm-block (一人僅有一次 中獎機會)
                  button.download-button.mt-2.mt-md-0 中獎回覆函下載
                p.remind-word.text-center.text-sm-start (獎項於每週一抽出，從活動第二周06/21開抽，為期8周，共送出8名，共16箱)
              .content.detail-3
                p.step-content-title 活動結束後，依照【投票數】選出三位應援王!
                p.item.d-flex.flex-column.align-items-center.d-sm-block ・ 最強應援冠軍: 新台幣3萬元現金獎，共1名
                  button.download-button.mt-2.mt-md-0 中獎回覆函下載
                p.item.d-flex.flex-column.align-items-center.d-sm-block ・ 最強應援亞軍: 新台幣2萬元現金獎，共1名
                  button.download-button.mt-2.mt-md-0 中獎回覆函下載
                p.item.d-flex.flex-column.align-items-center.d-sm-block ・ 最強應援季軍: 新台幣1萬元現金獎，共1名
                  button.download-button.mt-2.mt-md-0 中獎回覆函下載
    
    cross-banner

    //- If not add overflow-hidden in this section then click navigator will cause bug.
    section#attention.attention-section.py-5.position-relative.overflow-hidden
      page-observer(status="attention")

      home-decoration-firework.position-absolute.decoration-firework-right(:index="7")
      home-decoration-firework.position-absolute.decoration-firework-left(:index="8")
      home-decoration-thunder.position-absolute.decoration-thunder-right.rellax(data-rellax-speed="3")
      home-decoration-thunder.position-absolute.decoration-thunder-left.purple.rellax(data-rellax-speed="3")

      .container.position-relative.px-sm-5
        .row
          .col-12
            .flex-center.mb-5.pb-5.position-relative
              .loudspeaker
              decoration-title(name="注意事項" class="normal")
            ul.attention-block.px-sm-5(data-aos="fade-in")
              li.text-left(v-for="(data, index) in attentionData" :key="index") {{ data }}
      //- div(@click="loginFb") 登入
      //- div(@click="logoutFB") 登出
      //- div fbid: {{userFbId}}
      //- div(:style="{marginBottom: '100px'}") Name: {{userName}}

</template>

<script>
import { ref, computed, onMounted } from "vue";
import PageObserver from "@/components/PageObserver";
import VideoMask from "@/components/VideoMask";
import VideoFrame from "@/components/VideoFrame";
import BottomBanner from "@/components/BottomBanner";
import Navigator from "@/components/Navigator";
import MobileNavigator from "@/components/MobileNavigator";
import TwTitle from "@/components/TwTitle";
import DecorationTitle from "@/components/DecorationTitle";
import HomeDecorationFirework from "@/components/HomeDecorationFirework";
import HomeDecorationThunder from "@/components/HomeDecorationThunder";
import CrossBanner from "@/components/CrossBanner";
import InstagramThumbnail from "@/components/InstagramThumbnail";
import Parallax from "parallax-js";
import Rellax from "rellax";
import Splide from "@splidejs/splide";
import "@splidejs/splide/dist/css/splide.min.css";
import aos from "aos";
import "aos/dist/aos.css";
import { createUser } from "@/api";
import { mapState } from 'vuex'

// facebook utils
import { initFacebookSDK } from "@/utils/init-facebook-sdk";
import { createOrGetUser, vote, getVideos } from "@/api";
import request from "@/utils/request";

export default {
  name: "Home",
  components: {
    PageObserver,
    VideoMask,
    BottomBanner,
    TwTitle,
    DecorationTitle,
    HomeDecorationFirework,
    HomeDecorationThunder,
    CrossBanner,
    Navigator,
    MobileNavigator,
    InstagramThumbnail,
    VideoFrame
  },
  setup() {
    const scene = ref(null);
    const mobileNavigatorVisible = ref(false);
    const parallax = ref(null);

    onMounted(() => {
      parallax.value = new Parallax(scene.value, {
        relativeInput: true,
        hoverOnly: true,
      });

      handleResize();
      window.addEventListener("resize", handleResize);

      new Rellax(".rellax");

      aos.init({
        once: true,
      });
    });

    function handleResize() {
      if (window.innerWidth <= 992) {
        parallax.value.limit(0, 0);
      } else {
        parallax.value.limit(10, 10);
      }
      if (window.innerWidth >= 767.98) {
        mobileNavigatorVisible.value = false;
      }
    }

    const instagramsData = ref([
      {
        avatar: "https://i.pravatar.cc/60",
        image: "https://fakeimg.pl/300x180/",
        name: "記得吃飯呀",
        tickets: 1598,
      },
      {
        avatar: "https://i.pravatar.cc/60",
        image: "https://fakeimg.pl/300x180/",
        name: "關於搞笑，我們是認真的",
        tickets: 6683,
      },
      {
        avatar: "https://i.pravatar.cc/60",
        image: "https://fakeimg.pl/300x180/",
        name: "摸摸手",
        tickets: 4231,
      },
      {
        avatar: "https://i.pravatar.cc/60",
        image: "https://fakeimg.pl/300x180/",
        name: "工作別太累",
        tickets: 4812,
      },
      {
        avatar: "https://i.pravatar.cc/60",
        image: "https://fakeimg.pl/300x180/",
        name: "陶朱隱園我家後院",
        tickets: 1109,
      },
      {
        avatar: "https://i.pravatar.cc/60",
        image: "https://fakeimg.pl/300x180/",
        name: "教練，我想打籃球",
        tickets: 8312,
      },
      {
        avatar: "https://i.pravatar.cc/60",
        image: "https://fakeimg.pl/300x180/",
        name: "被冰塊燙傷",
        tickets: 9142,
      },
      {
        avatar: "https://i.pravatar.cc/60",
        image: "https://fakeimg.pl/300x180/",
        name: "涼麵趁熱吃",
        tickets: 4123,
      },
      {
        avatar: "https://i.pravatar.cc/60",
        image: "https://fakeimg.pl/300x180/",
        name: "記得吃飯呀",
        tickets: 1598,
      },
      {
        avatar: "https://i.pravatar.cc/60",
        image: "https://fakeimg.pl/240x180/",
        name: "喝喝水",
        tickets: 6683,
      },
      {
        avatar: "https://i.pravatar.cc/60",
        image: "https://fakeimg.pl/240x180/",
        name: "摸摸手",
        tickets: 4231,
      },
      {
        avatar: "https://i.pravatar.cc/60",
        image: "https://fakeimg.pl/240x180/",
        name: "工作別太累",
        tickets: 4812,
      },
    ]);

    const videoSortTypes = ref([
      { name: "新 &rarr; 舊", sort: "ASC", trigger: true },
      { name: "舊 &rarr; 新", sort: "DESC", trigger: false },
      { name: "人氣", sort: "popular", trigger: false },
    ]);

    const attentionData = ref([
      "凡參與本活動之消費者，視為同意上述活動辦法與以下注意事項。",
      "本活動期間，消費者參與此活動的投稿內容，視同完整授權臺灣菸酒股份有限公司使用(包括但不限於圖像與肖像之重製、公開播送及公開傳輸等 )，臺灣菸酒股份有限公司得永久無償於台灣地區或網際網路、實體活動上，使用該投稿內容於台灣啤酒行銷相關活動中。",
      "本次活動主辦單位為臺灣菸酒股份有限公司，委由聯眾廣告公司執行本次活動。",
      "本活動上傳影片者年齡須年滿18歲。同一帳號上傳僅限一次，且同一支影片僅限以一個帳號上傳。",
      "本活動得獎人經活動人員通知後，須備妥中獎確認函(現金獎者須附中獎匯款同意書)，並於110年8月13日前回函「正本」，逾期、資料填寫不全或錯誤至無法聯繫、寄送者，視同自動放棄權利(以郵戳為憑)。",
      "以任何駭客或其它非法行為破壞活動規則者，中獎無效，主辦單位並對其參加者或得獎者保留法律追訴權，如經主辦單位評估參加活動者上傳之內容與本活動主題無關或不雅內容，主辦單位保有刪除該內容之權利。",
      "如因寄送過程中發生信件遺失、信封破損或其他不可歸責於主辦單位之原因，導致兌獎憑證遺失或無法辨識，或中獎者資料填寫不完整或不清楚，導致未能或不符資格兌獎，得獎者同意主辦單位無需為此負任何責任。",
      "中獎者應保證所有填寫或提出之資料均為真實且正確，並未冒用或盜用他人資料，如有不實或不正確資訊，主辦單位將取消其中獎資格，並就其損害主辦單位或任何第三人權益，保留一切法律追訴之權利。",
      "本活動屬競技競賽及機會中獎所得，依所得稅法之規定，年度累積中獎價值若超過NT$1,000整，應列入得獎人年度綜合所得，主辦單位依所得稅法第94-1條之規定，得免填發各類所得扣繳暨免扣繳憑單予得獎人，若得獎人有需求時請另行提出申請。",
      "本活動之所有實體贈品不得轉換或折換現金",
      "獎項以公佈於本網站上的資料為準，如遇不可抗力之因素，主辦單位保留修改活動與獎項細節的權利，無須事前通知，並有權對本活動所有事宜作出解釋或裁決。如本活動因不可抗力之特殊原因無法執行時，主辦單位有權決定取消、終止、修改或暫停本活動，如有任何未盡事宜均以主辦單位依中華民國法律解釋之。",
      "中獎者須同意遵守各獎品供應商所列有關獎品之各項條款及細則，且中獎者同意主辦單位對所有領取或使用獎品之後果概無須負責。",
      "本活動網頁之所有內容由主辦單位擁有所有著作權，未經許可，不得抄襲、拷貝與複製相關圖片與文字，主辦單位保留所有侵權事宜之法律追訴權利。",
      "中獎者參與本活動時，視為同意接受本活動所有內容及細則之規範；倘有違反本活動相關注意規範，主辦單位得取消其中獎資格，並對於任何破壞本活動之行為保留法律追訴權。",
      "主辦單位僅使用中獎者所提供之個人資料於此次活動用途，且遵守【個人資料保護法】相關規定，以維護參加者權益。得獎者同意中獎相關個人資訊由主辦單位於活動範圍內進行蒐集、電腦處理及利用，但不做其他用途；另為確認中獎之名單，得獎者並授權主辦單位得公開公佈其姓名。",
      "活動之抽獎委由聯眾廣告股份有限公司利用線上虛擬抽獎程式抽出，過程由台灣菸酒股份有限公司行銷人員監督，並留存抽獎影片，確保抽獎過程公平公正公開。",
      "本活動若有其他未盡事宜，或有任何變更或修改，則依活動網站公告為主，不再另行通知。",
      "活動相關疑問請洽詢活動小組專線：02-26561106 (服務時間週一~週五10:00~18:00不含例假日)。",
    ]);

    return {
      scene,
      mobileNavigatorVisible,
      instagramsData,
      videoSortTypes,
      attentionData,
    };
  },
  data: () => {
    return {
      showMask: false,
      userFbId: null,
      userName: null,
      userPic: null,
      userPicBase64: null,
      userCanVote: false,
      isUserLogin: false,
      showPlayVideo: false,
      splide: null,
      selectedVideo: {
        url: ''
      }
    };
  },
  computed: {
    ...mapState(['videos','videosLoaded','instagramTopThree','browser']),
    configInstagramData(){
      if(this.videos){
        let storageBaseUrl = process.env.VUE_APP_FILE_BASE_URL
        let data = this.videos
        let adjustedData = []
        for (let i = 0; i < data.length; i += 2)
          adjustedData.push(data.slice(i, i + 2));

        return adjustedData
      }else{
        return []
      }
    }
  },
  mounted() {
    let scene = document.getElementsByClassName("parallax-scene")[0];
    new Parallax(scene, {
      selector: ".parallax-layer",
      pointerEvents: true,
    });

    this.splide = new Splide(".splide", {
        perPage: 3,
        breakpoints: {
          575.98: {
            perPage: 1,
          },
        },
      }).mount();

    setTimeout(() => {
      this.showMask = true;
      window.dispatchEvent(new Event('resize'));
    }, 3000);
    
    // 確認用有無登入紀錄，有的話直接抓取用戶資料
    initFacebookSDK().then(()=>{
      this.checkFbStatus()
    }).then(()=>{
      if(this.isUserLogin){
        console.log('user init is logged in, fetching data')
        this.getUserFbData()
      }
    })
  },
  methods: {
    videoSort(idx) {
      let type
      this.videoSortTypes.map((el, index) => {
        if(index === idx){
          el.trigger = true;
          type = el.sort
        }else{
          el.trigger = false;
        }
      });

      this.$store.dispatch('sortVideos', type)
      this.splide.refresh()
    },
    vote(videoId){
      console.log(videoId)
      // check if user is login
      if(this.isUserLogin){
        if(this.userCanVote){
          vote(this.userFbId, videoId).then(response=>{
            console.log('vote response')
            console.log(response)
            if(response.status==="success"){
              this.$store.dispatch('addOneVoteToVideo', videoId)
              this.userCanVote = false
              alert('投票成功！')
            }else{
              alert('您今日已經投票過，請明天再來')
            }
          })
        }else{
          alert('您今日已經投票過，請明天再來')
        }
        
      }else{
        alert('請登入 FB 以繼續');
        this.loginFb().then(res=>{
          console.log('login to FB before vote')
          console.log(res)
        }).then(res=>{
          this.getUserFbData()

          if(this.userCanVote){
            vote(this.userFbId, videoId).then(response=>{
              if(response.status==="success"){
                this.$store.dispatch('addOneVoteToVideo', videoId)
                this.userCanVote = false
                alert('投票成功！')
              }else{
                console.log('0.0')
                alert('您今日已經投票過，請明天再來')
              }
            })
          }else{
            alert('您今日已經投票過，請明天再來')
          }
        })
        .catch(err=>{
          console.log(err)
        })
      }
    },
    playVideo(data){
      this.selectedVideo.url = data.url
      this.showPlayVideo = true
    },
    loginFb(){
      return new Promise((resolve,reject)=>{
        FB.login((response) => {
          console.log('login')
          console.log(response)
          if(response.status === "connected"){
            this.isUserLogin = true;
            this.userFbId = response.authResponse.userID;
            this.getUserFbData().then(res=>{
              resolve("User Auth and logged in")
            })
          }else{
            console.log('User cancelled login or did not fully authorize.')
            reject('User cancelled login or did not fully authorize.')
          }
        })
      })
    },
    checkFbStatus() {
      return new Promise((resolve) => {
        FB.getLoginStatus((response) => {
            // console.log(response)
          if (response.status === "connected") {
            this.isUserLogin = true;
            this.userFbId = response.authResponse.userID;
          }else{
              console.log('User is not logged in')
          }
          resolve();
        });
      });
    },
    getUserFbData() {
      return new Promise(resolve => {
        FB.api("/me", { fields: "id,name,picture" }, (response) => {
          console.log('get user FB data: ')
          console.log(response);
          this.userName = response.name;
          this.userPic = response.picture.data.url;
          this.getImg().then(()=>{
            createOrGetUser({
              "fbuid": this.userFbId,
              "username": this.userName,
              "userPic": this.userPicBase64
            }).then(res=>{
              console.log('User data after get/create')
              console.log(res)
              this.userCanVote = res.canVote
              resolve()
            })
          })
        })
      })
    },
    getImg() {
      return new Promise(resolve=>{
        const reader = new FileReader();
        request({
          responseType: "blob",
          url: this.userPic,
          method: "get",
        }).then((res) => {
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(res);
          }).then(res => {
            this.userPicBase64 = res
            resolve()
          });
        });
      })
    },
    logoutFB() {
      FB.logout((response) => {
        this.isUserLogin = false;
        this.userName = null;
        this.userFbId = null;
      });
    },
    createOrGetUser() {
      let userData = {
        fbuid: this.userFbId,
        username: this.userName,
        userPic: this.userPicBase64,
      };
      createOrGetUser(userData).then((response) => {
        console.log(response);
      });
    },
  },
  watch: {
    videosLoaded(){
      this.$nextTick(()=>{
        this.splide.refresh();
        window.dispatchEvent(new Event('resize'));
      })
    }
  }
};
</script>

<style lang="sass" scoped>

@import '../assets/css/customAnimation.sass'

.dev-controls
  position: fixed
  right: 10px
  bottom: 100px
  width: 200px
  color: white
  z-index: 9999
  display: none

.home-wrapper
  background-color: #0e693d
.campaign-section
  height: 100vh
  min-height: 1080px
  overflow: hidden
  background-image: url("../assets/images/campaign-bg.jpg")
  background-attachment: fixed
  background-size: 100% 200vh
  background-position: top left
  z-index: 2
  pointer-events: auto !important

  .layer
    width: 100%
    height: 100%
    pointer-events: auto !important

    .second-layer
      width: 100%
      height: 100%

  .logo-icon
    left: 4% !important
    top: 4% !important
    width: 123px
    height: 109px
    background-image: url("../assets/images/logo-mobile.svg")
    background-size: contain
    background-position: center
    background-repeat: no-repeat
    z-index: 100

  .hand-hold-beer
    right: 5%
    bottom: 0
    background-image: url("../assets/images/hand-hold-beer.svg")
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    width: 389px
    height: 810px

  .hash-tag-left
    left: 10%
    top: 40%
    transform: rotate(-15deg)
    font-family: 'Noto Sans TC', sans-serif
    font-size: 20px
    border-radius: 50px
    letter-spacing: 4px
    text-indent: 4px
    p
      padding: 12px 36px
      background-color: #F2F0DC
      box-sizing: border-box
      border-radius: 50px
      border: 2px solid #000000
      &:before
        content: ""
        width: calc(100% + 20px)
        height: calc(100% + 20px)
        position: absolute
        border-radius: 50px
        left: -10px
        top: -10px
        background-color: #FDD351
        z-index: -1
        border: 3px solid #000000
        box-sizing: border-box
      &:after
        content: ""
        width: 110%
        height: 110%
        position: absolute
        border-radius: 50px
        left: 0
        top: 15px
        background-color: transparent
        background-image: radial-gradient(circle, #322826 20%, transparent 10%), radial-gradient(circle, #322826 20%, transparent 10%)
        background-size: 6px 6px
        z-index: -2

  .hash-tag-right
    right: 12%
    bottom: 35%
    font-family: 'Noto Sans TC', sans-serif
    transform: rotate(-15deg)
    font-size: 20px
    border-radius: 50px
    letter-spacing: 4px
    text-indent: 4px

    p
      padding: 12px 36px
      background-color: #F2F0DC
      box-sizing: border-box
      border-radius: 50px
      border: 2px solid #000000
      &:before
        content: ""
        width: calc(100% + 20px)
        height: calc(100% + 20px)
        position: absolute
        border-radius: 50px
        left: -10px
        top: -10px
        background-color: #FDD351
        z-index: -1
        border: 3px solid #000000
        box-sizing: border-box
      &:after
        content: ""
        width: 110%
        height: 110%
        position: absolute
        border-radius: 50px
        left: 0
        top: 15px
        background-color: transparent
        background-image: radial-gradient(circle, #322826 20%, transparent 10%), radial-gradient(circle, #322826 20%, transparent 10%)
        background-size: 6px 6px
        z-index: -2

  .social-button-group
    top: 6%
    right: 10%
    height: 35px
    width: 140px
    display: flex
    z-index: 100

    .social-button
      background-size: cover
      background-position: center
      width: 35px
      height: 35px
      display: block
      cursor: pointer
      &.facebook
        background-image: url("../assets/images/facebook-social-icon.svg")
        margin-right: 24px
      &.instagram
        background-image: url("../assets/images/instagram-social-icon.svg")

    @media screen and (max-width: 1300px)
      right: 2%
      .social-button.facebook
        margin-right: 8px

    @media screen and (max-width: 991.98px)
      .social-button.instagram
        margin-right: 8px

      .hamburger-menu
        width: 35px
        height: 35px
        background-color: #140F6B
        border: 1px solid #ffffff
        -webkit-border-radius: 6px
        -moz-border-radius: 6px
        border-radius: 6px
        position: relative
        span
          position: absolute
          width: calc(100% - 8px)
          height: 2px
          background-color: #ffffff
          display: block
          left: 50%
          top: 50%
          transform: translate(-50%, -50%)
          &:last-child
            top: 25%
          &:first-child
            top: 75%

  .campaign-title
    text-align: center
    font-size: 48px

  .campaign-wrapper
    margin-top: 56px
    .campaign-block
      position: relative
      max-width: 660px
      &:after
        content: ""
        width: 1348px
        height: 900px
        position: absolute
        top: 10%
        left: 56%
        transform: translate(-50%, -50%)
        background-image: url("../assets/images/campaign-group.png")
        background-position: center
        background-size: contain
        background-repeat: no-repeat
        z-index: -1

      .loudspeaker
        position: absolute
        left: 0
        top: -24%
        width: 140px
        height: 140px
        background-image: url("../assets/images/loudspeaker.svg")
        background-position: center
        background-size: cover
        background-repeat: no-repeat
        z-index: 20

      .fighting
        position: absolute
        top: -45%
        left: -35%
        transform: rotate(-25deg)
        color: #140F6B
        font-size: 20px
        font-weight: 500
        z-index: 1

      .campaign-article
        padding: 7% 10%
        box-sizing: border-box
        font-family: 'Noto Sans TC', sans-serif
        font-size: 25px
        background-image: url("../assets/images/campaign-article-bg.svg")
        background-position: center
        background-size: cover
        background-repeat: no-repeat
        position: relative
        z-index: 2
        p
          font-weight: 500
          font-size: 40px
          &:last-child
            font-weight: 400
            font-size: 25px

  .campaign-button-group
    max-width: 640px
    margin-top: 24px
    margin-left: auto
    margin-right: auto
    display: flex
    justify-content: space-between

    a
      text-decoration: none
      display: block
      text-align: center
      line-height: 60px
      color: #ffffff
      font-size: 25px
      background-color: inherit
      background-image: url("../assets/images/campaign-button.svg")
      background-size: cover
      background-repeat: no-repeat
      background-position: center
      padding: 0 11%
      transition: all .3s

      &:hover
        color: inherit
        text-shadow: -1px -1px 0 #ffffff, 0 -1px 0 #ffffff, 1px -1px 0 #ffffff, 1px 0 0 #ffffff, 1px 1px 0 #ffffff, 0 1px 0 #ffffff, -1px 1px 0 #ffffff, -1px 0 0 #ffffff

  @media (max-width: 1980px) and (max-height: 1080px)
    .campaign-wrapper
      margin-top: 5%
  @media screen and (max-width: 1680px)
    min-height: 1050px
    .campaign-wrapper
      margin-top: 0
      .campaign-block
        .campaign-article
          transform: scale(0.9)
          transform-origin: top center
        .campaign-button-group
          margin-top: -5%
  @media screen and (max-width: 1440px)
    min-height: 900px
    .campaign-wrapper
      margin-top: 5%
  @media (max-width: 1440px) and (max-height: 900px)
    min-height: 900px
    .campaign-wrapper
      margin-top: 5%
      .campaign-block
        &:after
          width: 1100px
          height: 734.42px
          top: 6%
        .campaign-article
          transform: scale(0.8)
        .loudspeaker
          top: -12%
        .fighting
          top: -38%
          left: -20%
        .campaign-button-group
          margin-top: -8%
  @media screen and (max-width: 1199.98px)
    .campaign-wrapper
      margin-top: 48px
    .hash-tag-right
      bottom: 10%
      right: 0
  @media screen and (max-width: 991.98px)
    .hash-tag-left
      top: 50%
      left: -5%
    .campaign-wrapper
      margin-top: 10%
      .campaign-block
        &:after
          left: 64%
          transform: translate(-50%, -50%) scale(0.9)
        .campaign-article
          padding: 13% 10%
          p
            font-size: 25px
            &:last-child
              font-size: 20px
        .campaign-button-group
          max-width: 528px
          a
            background-size: contain
            font-size: 20px
  @media screen and (max-width: 767.98px)
    .hash-tag-right
      bottom: 12%
      right: 0
    .hand-hold-beer
      left: 50%
      bottom: 0
      transform: translateX(-50%) scale(0.3)
      transform-origin: bottom center
    .hash-tag-left
      top: auto
      bottom: 12%
    .campaign-wrapper
      margin-top: 15%
      .campaign-block
        .fighting
          left: -20%
          top: -40%
          transform: scale(0.8) rotate(-30deg)
        &:after
          width: 800px
          height: 534.12px
          top: 0
          left: 58%
          transform: translate(-50%, -50%) scale(1)
        .campaign-article
          padding: 12% 10%
          p
            font-size: 25px
            &:last-child
              font-size: 16px
    .campaign-button-group
      margin-top: 5%
      transform: scale(1)
      padding: 0 24px
      a
        font-size: 16px
        background-size: contain
        padding: 24px 60px
  @media screen and (max-width: 575.98px)
    .hash-tag-right
      transform: scale(0.6) rotate(-15deg)
      bottom: 5%
    .hash-tag-left
      transform: scale(0.6) rotate(-15deg)
      bottom: 10%
      left: -10%
    .campaign-wrapper
      margin-top: 20%
      .campaign-block
        .fighting
          left: -2%
          top: -42%
          font-size: 14px
        .loudspeaker
          width: 80px
          height: 80px
          top: -4px
        &:after
          width: 700px
          height: 464.73px
          top: -18px
        .campaign-article
          padding: 10%
        .campaign-button-group
          a
            font-size: 16px
  @media screen and (max-width: 481.98px)
    .campaign-wrapper
      .campaign-block
        .campaign-article
          padding: 8%
  @media screen and (max-width: 460.98px)
    .campaign-wrapper
      .campaign-block
        &:after
          width: 500px
          top: -24px
    .campaign-button-group
      padding: 0
      max-width: 345px
      a
        padding: 0 13%
        transform: scale(0.9)
  @media screen and (max-width: 451.98px)
    .campaign-wrapper
      .campaign-block
        .campaign-article
          padding: 7%
        .loudspeaker
          top: -20px
  @media screen and (max-width: 441.98px)
    .campaign-wrapper
      .campaign-block
        max-width: 375px
        margin-top: -2%
        &:after
          top: -15px
        .fighting
          left: -9%
          top: -32%
        .campaign-article
          padding: 12%
          background-image: url("../assets/images/campaign-article-mobile-bg.svg")
  @media screen and (max-width: 390.98px)
    .campaign-wrapper
      .campaign-block
        .fighting
          left: 0
          top: -40%
        .loudspeaker
          left: 15px
        .campaign-article
          width: 375px
          background-size: contain
          background-position: top center
          p
            margin-bottom: 0
        .campaign-button-group
          max-width: 320px
  @media screen and (max-width: 340.98px)
    .campaign-wrapper
      margin-top: 30%

.teach-section
  background-color: #0E693D
  .teach-block
    max-width: 812px
    padding-top: 48px
    margin-right: auto
    margin-left: auto

  .decoration-firework-left
    left: 5%
    top: 15%

  .decoration-thunder-right
    right: 10%
    top: 50%
  .dance-title
    text-align: center
    background-image: url("../assets/images/video-title.svg")
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    color: #ffffff
    padding: 0 126px
    margin-top: 24px
    margin-bottom: 24px
    font-size: 30px
    line-height: 90px
  .dance-subtitle
    color: #ffffff
    font-size: 25px
    font-weight: 500
    margin-bottom: 24px
  .video-block
    background-image: url("../assets/images/teach-bg.svg")
    background-size: contain
    background-position: center
    background-repeat: no-repeat
    max-width: 812px
    height: 940px

  .decoration-1
    left: 14%
    top: 5%

  .decoration-2
    right: 14%
    bottom: 5%

  @media screen and (max-width: 991.98px)
    .decoration-firework-left
      width: 160px
    .decoration-thunder-right
      width: 90px
      right: 0
    .dance-title
      font-size: 25px
      padding: 0 140px
    .dance-subtitle
      font-size: 20px
    .video-block
      .dance-video
        height: 640px
  @media screen and (max-width: 767.98px)
    .video-block
      height: 600px
      background-position: top left
      .dance-video
        height: 480px
  @media screen and (max-width: 575.98px)
    .video-block
      background-position: center
      height: 300px
      .dance-video
        height: 260px
    .decoration-1
      width: 36px
    .decoration-2
      width: 36px
    .dance-title
      background-image: url("../assets/images/video-mobile-title.svg")
      background-size: contain
      background-repeat: no-repeat
      font-size: 16px
      line-height: 48px
      width: 100%
      padding: 0
    .dance-subtitle
      font-size: 12px

.step-section
  background-color: #0E693D

  .decoration-firework-right
    right: 5%
    top: -20%
    transform: rotate(220deg)

  .decoration-thunder-right
    right: 10%
    bottom: 10%

  .decoration-thunder-left
    left: 15%
    top: 0

  .step-block
    max-width: 812px
    margin-left: auto
    margin-right: auto
    background-image: url("../assets/images/step-bg.svg")
    background-size: 100%
    background-position: center
    background-repeat: no-repeat
    z-index: 3
    p
      font-size: 25px
      text-align: center
      font-weight: 500
      position: relative
      span
        font-size: 20px
        font-weight: 400
      &:before
        content: ""
        position: absolute
        left: -120px
        width: 170px
        height: 85px
        background-size: 100%
        background-position: center
        background-repeat: no-repeat
    .step-1
      padding: 6% 15% 11%
      &:before
        top: 0
        background-image: url("../assets/images/step-1.svg")
    .step-2
      padding: 0 15% 8%
      &:before
        top: -50%
        background-image: url("../assets/images/step-2.svg")
      &:after
        content: ""
        position: absolute
        right: -40%
        top: 20%
        transform: translateY(-50%)
        background-image: url("../assets/images/step-boy-group.png")
        background-size: cover
        background-position: center
        width: 603px
        height: 402px
        animation: shaking-steps 0.8s infinite
    .step-3
      padding: 3% 15% 7%
      &:before
        top: -40%
        background-image: url("../assets/images/step-3.svg")
      &:after
        content: ""
        position: absolute
        left: 0
        bottom: -45%
        background-image: url("../assets/images/step-girl-group.png")
        background-size: cover
        background-position: center
        width: 180px
        height: 400px
        animation: jumping 0.8s infinite
        animation-delay: 0.5s

  .submit-button-block
    max-width: 812px
    margin-left: auto
    margin-right: auto
    .submit-button
      background-image: url("../assets/images/step-submit-button.svg")
      background-size: contain
      background-position: center
      background-repeat: no-repeat
      background-color: inherit
      color: #ffffff
      padding: 2% 8% 2% 10%
      border: none
      position: relative
      font-size: 25px
      font-weight: 400
      transition: all .3s
      a
        text-decoration: none
        color: white
      &:before
        content: ""
        background-image: url("../assets/images/step-submit-button-icon.svg")
        background-size: contain
        background-repeat: no-repeat
        background-position: center
        position: absolute
        width: 30px
        height: 30px
        left: 15%
        top: 50%
        transform: translateY(-50%)
      &:hover
        color: inherit
        text-shadow: -1px -1px 0 #ffffff, 0 -1px 0 #ffffff, 1px -1px 0 #ffffff, 1px 0 0 #ffffff, 1px 1px 0 #ffffff, 0 1px 0 #ffffff, -1px 1px 0 #ffffff, -1px 0 0 #ffffff
        a
          color: inherit

  .remind-block
    max-width: 812px
    background-image: url("../assets/images/remind-bg.svg")
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    padding: 4.5% 9%

    p
      font-size: 25px
      font-weight: 500
      margin-bottom: 0

    &:before
      content: ""
      position: absolute
      left: -8%
      top: 78%
      transform: translate(-50%, -50%)
      width: 208px
      height: 208px
      background-image: url("../assets/images/loudspeaker.svg")
      background-size: cover
      background-repeat: no-repeat

  .alert-word
    max-width: 812px
    text-align: center
    color: #ffffff
    font-family: "Noto Sans TC", sans-serif
    font-size: 20px
    margin-bottom: 10%
  @media screen and (max-width: 1399.98px)
    .remind-block
      padding: 4.2% 9%
  @media screen and (max-width: 1199.98px)
    .step-block
      p
        &:before
          left: -20px
      .step-1
        &:before
          top: -30px
      .step-2
        &:before
          top: -45%
        &:after
          right: -20%
          width: 400px
          height: 266.67px
      .step-3
        &:before
          top: -30%
        &:after
          bottom: -50%
          left: 5%
          width: 120px
          height: 266.67px
    .remind-block
      padding: 5% 9%
      &:before
        width: 140px
        height: 140px
        left: 0
  @media screen and (max-width: 991.98px)
    .decoration-firework-right
      width: 120px
    .decoration-thunder-left
      width: 100px
      left: 0
    .decoration-thunder-right
      width: 80px
    .step-block
      p
        font-size: 18px
        span
          font-size: 16px
        &:before
          transform: scale(0.8)
      .step-1
        padding: 7% 15% 12%
        &:before
          top: -20%
      .step-2
        &:after
          right: -24%
        &:before
          top: -55%
      .step-3
        padding: 6% 15% 8%
        &:after
          left: -20px
          bottom: -40%
    .submit-button-block
      .submit-button
        font-size: 18px
        &:before
          width: 20px
          height: 20px
          left: 20%
    .remind-block
      padding: 6.8% 8%
      background-size: contain
      p
        font-size: 18px
      &:before
        width: 94px
        height: 94px
        left: 0
  @media screen and (max-width: 767.98px)
    .step-block
      background-position: top center
      p
        font-size: 16px
        span
          font-size: 14px
        &:before
          transform: scale(0.6)
          left: -10%
      .step-1
        padding: 6% 15% 8%
        &:before
          top: -25%
      .step-2
        padding: 0 15% 4%
        &:after
          width: 200px
          height: 133.34px
          top: -100%
          right: -15%
          z-index: 1000
        &:before
          top: -50%
      .step-3
        padding-top: 2%
        &:before
          top: -42%
        &:after
          width: 80px
          height: 177.78px
          bottom: -50%
    .submit-button-block
      .submit-button
        font-size: 16px
        &:before
          width: 16px
          height: 16px
    .remind-block
      &:before
        top: 160%
        transform: translateY(-50%)
      p
        font-size: 12px
    .alert-word
      padding: 0 26%
      font-size: 12px
  @media screen and (max-width: 575.98px)
    .step-block
      max-width: 375px
      background-image: url("../assets/images/step-mobile-bg.svg")
      background-position: top center
      background-size: contain
      p
        line-height: 1.3
      .step-1
        padding: 8% 10% 6%
        &:before
          top: -40%
      .step-2
        padding: 4% 10% 10%
        &:before
          top: -35%
        &:after
          top: -106%
      .step-3
        padding: 0 10% 10%
        &:before
          top: -58%
        &:after
          bottom: -100%
    .submit-button-block
      .submit-button
        &:before
          left: 12%
    .remind-block
      &:before
        left: -15px
    .alert-word
      padding: 0 18%
      margin-bottom: 0
  @media screen and (max-width: 414.98px)
    .step-block
      margin-top: 13% !important
      max-width: 320px
      p
        &:before
          left: 0
          transform: scale(0.5)
          transform-origin: left center
      .step-2
        &:after
          top: -115%
      .step-3
         &:after
          bottom: -110%
    .remind-block
      padding: 6%
      &:before
        width: 70px
        height: 70px
        left: 0
        top: 150%
  @media screen and (max-width: 343.98px)
    .step-block
      .step-1
        padding: 6% 10% 2%
        &:before
          top: -50%
      .step-2
        padding: 5% 10% 9%
        &:before
          top: -30%
      .step-3
        &:before
          top: -54%
  @media screen and (max-width: 342.98px)
    .step-block
      background-image: url("../assets/images/step-mobile-bg-long.svg")
      .step-2
        &:before
          top: -26%
  @media screen and (max-width: 323.98px)
    .step-block
      .step-1
        padding: 4% 10%
        &:before
          top: -48%
      .step-2
        padding: 2% 10% 4%
        &:before
          top: -38%
        &:after
          top: -120%
      .step-3
        padding: 5% 10% 15%
        &:after
          bottom: -110%
        &:before
          top: -32%

.instagram-section
  background-color: #0E693D
  margin-bottom: 2%

  .decoration-firework-left
    left: 5%
    top: 0
    transform: rotate(40deg)

  .decoration-firework-left-2
    left: 0
    top: 0

  .decoration-firework-right
    right: 0
    bottom: 0
    transform: rotate(220deg)

  .decoration-thunder-left
    left: 10%
    bottom: 10%

  .slogan-block
    left: 1%
    top: 0
    max-width: 254px
    padding-bottom: 8px
    padding-left: 2.5%
    padding-right: 2%
    z-index: 2
    p
      font-size: 30px
      font-family: "Noto Sans TC", sans-serif
      font-weight: 500
      color: #ffffff
      margin-bottom: 0
    &:before
      content: ""
      position: absolute
      top: 50%
      left: 50%
      width: 260px
      height: 182px
      transform: translate(-50%, -50%)
      background-image: url("../assets/images/video-dialog.svg")
      background-size: 100%
      background-repeat: no-repeat
    &:after
      content: ""
      position: absolute
      bottom: -5%
      right: 6%
      width: 69px
      height: 69px
      background-image: url("../assets/images/video-click-mouse.svg")
      background-size: 100%
      background-repeat: no-repeat

  .hot-instagram
    padding: 24px 10px !important
    z-index: 3

  .splide
    z-index: 3

  .video-button-group
    button
      margin-right: 20px
      background-color: #ffffff
      border: 2px solid #007abf
      padding: 4px 12px
      font-size: 20px
      font-weight: 500
      color: #007abf
      z-index: 2
      border-radius: 25px
      transition: all 0.3s
      &:first-child
        margin-left: 10px
      &:last-child
        margin-right: 0
      &.active, &:hover
        background-color: #007abf
        color: #ffffff
        border-color: #ffffff

      // popular font letter spacing
      &.letter-spacing
        letter-spacing: 20px
        text-indent: 20px
        padding: 4px 0

  @media screen and (max-width: 1399.98px)
    .slogan-block
      left: 0

  @media screen and (max-width: 991.98px)
    .decoration-firework-left
      width: 120px
    .decoration-firework-left-2
      width: 180px
    .decoration-firework-right
      width: 160px
    .decoration-thunder-left
      width: 80px
      left: 0
      top: 45%
    .slogan-block
      left: 50%
      top: 100%
      transform: translateX(-50%)
      padding: 0
      max-width: 340px
      &:before
        background-image: url("../assets/images/video-dialog-mobile.svg")
        background-size: cover
        width: 340px
        height: 52px
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
      &:after
        display: none
      p
        font-size: 16px
        padding-bottom: 16px
        width: 340px
        text-align: center
    .video-button-group
      button
        font-size: 16px

  @media screen and (max-width: 575.98px)
    .video-button-group
      button
        font-size: 20px

    .hot-instagram
      padding: 30px 10px !important
      &:first-child
        margin-top: 60px !important

    .splide__slide
      padding-left: 14px
      padding-right: 14px

  @media screen and (max-width: 375.98px)
    .video-button-group
      button
        font-size: 16px
    .slogan-block
      &:before
        width: calc(100% - 32px)
    .hot-instagram
      padding: 20px 10px !important
  @media screen and (max-width: 320.98px)
    .video-button-group
      button
        margin-right: 10px

.explanation-section
  background-color: #0E693D

  .decoration-firework-left
    left: 5%
    top: 50%
    transform: translateY(-50%)

  .decoration-thunder-right
    right: 12%
    top: 30%
    transform: translateY(-50%)

  .decoration-thunder-right-2
    right: 8%
    top: 36%
    transform: translateY(-50%) scale(0.4)

  .decoration-thunder-right-3
    right: 20%
    top: 30%
    transform: translateY(-50%) scale(0.25)

  .decoration-dialog
    left: 0
    top: 50%
    transform: translateY(-50%)
    color: #ffffff
    font-size: 30px
    background-image: url("../assets/images/explaination-dialog-0.svg")
    background-size: 100%
    background-repeat: no-repeat
    background-position: center
    padding: 12px 24px
    z-index: 3

  .step-block
    max-width: 812px
    margin-right: auto
    margin-left: auto
    background-image: url("../assets/images/explaination-bg.svg")
    background-repeat: no-repeat
    background-size: 100%
    background-position: center
    height: 1020px
    .content
      position: relative
      &:before
        content: ""
        position: absolute
        background-size: 100%
        background-position: center
        color: #ffffff
        font-size: 30px
        font-weight: 500
        left: -18%
        top: -15%

    .detail-1
      padding: 12% 0 12% 6%
      &:before
        content: "第一重：上傳就抽"
        background-image: url("../assets/images/explaination-dialog-1.svg")
        width: 310px
        height: 107px
        padding-top: 5%
        padding-left: 6%

    .detail-2
      padding: 10% 0 12% 6%
      &:before
        content: "第二重：週週抽"
        background-image: url("../assets/images/explaination-dialog-1.svg")
        width: 310px
        height: 107px
        padding-top: 5%
        padding-left: 8%

    .detail-3
      padding: 5% 0 12% 6%
      &:before
        top: -24%
        content: "第三重：最強票選"
        background-image: url("../assets/images/explaination-dialog-1.svg")
        width: 310px
        height: 107px
        padding-top: 5%
        padding-left: 6%

    .step-content-title
      font-weight: 500
      font-size: 25px
      font-family: "Noto Sans TC", sans-serif

    .item
      font-size: 25px
      font-family: "Noto Sans TC", sans-serif
      font-weight: 400
      margin-bottom: 8px

    .remind-word
      font-size: 20px
      font-family: "Noto Sans TC", sans-serif
      font-weight: 400
      margin-bottom: 8px

    .download-button
      margin-left: 8px
      background-color: #269844
      color: #ffffff
      font-size: 20px
      border: 1px solid #707070
      padding: 2px 48px 2px 24px
      border-radius: 30px
      position: relative
      &:after
        content: ""
        position: absolute
        right: 12px
        top: 50%
        transform: translateY(-50%)
        background-image: url("../assets/images/download-icon.svg")
        background-size: contain
        background-position: center
        background-repeat: no-repeat
        width: 28px
        height: 28px
    .decoration-hold-beer
      right: -28%
      top: 30%
      transform: translateY(-50%) scale(0.5)

  @media screen and (max-width: 1399.98px)
    .decoration-thunder-right
      right: 0
    .decoration-thunder-right-2
      display: none !important
    .decoration-thunder-right-3
      display: none !important

    .decoration-dialog
      left: 2%
  @media screen and (max-width: 1199.98px)
    .decoration-dialog
      left: -2%
    .step-block
      .content
        &:before
          left: -14%
  @media screen and (max-width: 991.98px)
    .decoration-dialog
      left: 50%
      transform: translate(-50%)
      top: 100%
      font-size: 20px
    .step-block
      margin-top: 10%
      background-position: top left
      height: auto
      .decoration-hold-beer
        top: 50%
        right: -30%
        transform: translateY(-50%) scale(0.3)
      .content
        &:before
          left: -5%
      .detail-1
        padding: 12% 0 0 6%
        &:before
          padding-top: 6%
          padding-left: 7%
          top: -20%
      .detail-2
        padding: 18% 8% 12% 6%
        &:before
          top: 0
          padding-top: 6%
          padding-left: 9%
      .detail-3
        padding: 8% 0 12% 6%
        &:before
          padding-top: 6%
          padding-left: 7%
      .step-content-title
        font-size: 20px
      .item, .remind-word
        font-size: 18px
      .download-button
        font-size: 18px
        &:after
          width: 24px
          height: 24px
  @media screen and (max-width: 767.98px)
    .decoration-thunder-left
      top: 50%
      transform: translateY(-50%)
      width: 80px
      left: -20px
    .decoration-thunder-right
      top: 25%
      width: 80px
    .decoration-firework-left
      width: 160px
      top: 10%
    .decoration-firework-right
      width: 120px
      right: 0
      bottom: -30%
      transform: rotate(180deg)
    .step-block
      .decoration-hold-beer
        top: 50%
        right: -40%
        transform: translateY(-50%) scale(0.2)
      .content
        &:before
          font-size: 16px
          text-align: center
          padding: 6% 0 0 3%
          width: 206px
          height: 72px
          background-size: cover
          background-repeat: no-repeat
      .detail-1
        padding: 4% 4% 0 4%
        &:before
          top: -30%
      .detail-2
        padding: 13% 4% 12%
        &:before
          top: -5%
      .detail-3
        padding: 0 4% 12% 4%
        &:before
          top: -34%
      .step-content-title
        font-size: 16px
        text-align: center
        padding: 0
        margin-bottom: 0
      .item, .remind-word
        font-size: 14px
      .download-button
        font-size: 16px
  @media screen and (max-width: 575.98px)
    .decoration-dialog
      width: 300px
      background-image: url("../assets/images/explaination-mobile-dialog-bg-0.svg")
      background-position: left center
      top: 90%
      left: 50%
      transform: translateX(-50%)
      font-size: 16px
      text-align: center
    .step-block
      margin-top: 10%
      max-width: 318px
      height: 972px
      width: 100%
      background-image: url("../assets/images/explaination-mobile-bg.webp")
      background-size: 100% 100%
      .decoration-hold-beer
        top: 50%
        left: 100%
        right: auto
        transform: translate(-50%, -50%) scale(0.2)
      .content
        &:before
          padding: 10.2% 0 0 4%
      .detail-1
        padding: 14% 4%
        &:before
          top: -12%
      .detail-2
        padding: 15% 4% 16%
        &:before
          top: -20%
      .detail-3
        padding: 18% 4% 12% 4%
        &:before
          top: -10%
      .step-content-title
        margin-bottom: 8px
  @media screen and (max-width: 328.98px)
    .step-block
      .detail-2
        padding: 12% 3% 16%
      .detail-3
        padding: 10% 3% 12%
        &:before
          top: -14%

.attention-section
  background-color: #0E693D

  .decoration-thunder-left
    left: 0
    top: 20%
    transform: translateY(-50%)

  .decoration-firework-left
    left: 0
    bottom: 0

  .decoration-firework-right
    top: -10%
    right: 0
    transform: rotate(200deg)

  .decoration-thunder-right
    right: 0
    bottom: -50%

  .loudspeaker
    position: absolute
    right: -10%
    top: 40%
    width: 208px
    height: 208px
    transform: translate(-50%, -50%)
    background-image: url("../assets/images/loudspeaker.svg")
    background-position: center
    background-size: cover
    background-repeat: no-repeat
    z-index: 20

  .attention-block
    padding-bottom: 10%
    padding-top: 8%
    li
      font-family: "Noto Sans TC", sans-serif
      font-weight: 500
      list-style: decimal
      text-align: left
      font-size: 18px
      letter-spacing: 1px
      line-height: 2
      color: #ffffff

  @media screen and (max-width: 1399.98px)
    .decoration-thunder-left
      top: 30%
      width: 120px

  @media screen and (max-width: 991.98px)
    .decoration-thunder-left
      left: 10%
      width: 84px
    .decoration-thunder-right
      width: 120px

    .loudspeaker
      width: 120px
      height: 120px
      top: 20%
      right: -5%
    .attention-block
      li
        font-size: 16px

  @media screen and (max-width: 767.98px)
    .decoration-thunder-right
      width: 84px
      top: 80%

    .decoration-thunder-left
      top: 100%

    .decoration-firework-left
      width: 160px

    .decoration-firework-right
      top: auto
      bottom: -20%
      width: 120px

    .loudspeaker
      width: 80px
      height: 80px
      top: 100%

    .attention-block
      li
        font-size: 14px

// cross banner image RWD
.cross-banner-image-1
  width: 100%
  @media screen and (max-width: 1399.98px)
    width: 80%
  @media screen and (max-width: 767.98px)
    width: 60%
  @media screen and (max-width: 575.98px)
    width: 120%

.cross-banner-image-2
  width: 100%
  @media screen and (max-width: 1399.98px)
    width: 80%
  @media screen and (max-width: 767.98px)
    width: 100%
  @media screen and (max-width: 575.98px)
    width: 150%
</style>
