<template lang="pug">
ul.campaign-navigator.d-none.d-lg-block
  li(v-for="({ name, toggleLink, active }, index) in navLinkLists" :key="index")
    a.nav-link(:href="toggleLink" :class="active ? 'active' : ''") {{ name }}
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "Navigator",
  setup() {
    const navLinkLists = ref([
      { name: "首頁", toggleLink: "#", active: true },
      { name: "活動辦法", toggleLink: "#step", active: false },
      { name: "票選影片", toggleLink: "#instagram", active: false },
      { name: "獎項說明", toggleLink: "#explanation", active: false },
      { name: "注意事項", toggleLink: "#attention", active: false },
    ]);

    const route = useRoute();

    watch(
      () => route.hash,
      () => {
        navLinkLists.value.map((el) => {
          el.active = el.toggleLink === route.hash;
        });
      },
      { immediate: true }
    );

    return { navLinkLists };
  },
};
</script>

<style lang="sass" scoped>
.campaign-navigator
  position: fixed
  z-index: 1999
  right: 16px
  top: 200px
  padding-left: 0 !important

  ul, li
    padding: 0
    margin: 0
    list-style: none

  .nav-link
    text-align: center
    font-weight: bold
    padding: 4px 16px
    margin-bottom: 8px
    color: #ffffff
    border-radius: 16px
    box-sizing: border-box
    border: 1px solid transparent
    transition: all 0.3s
    &:hover, &.active
      background-color: #181267
      border: 1px solid #ffffff
</style>
