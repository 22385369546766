<template>
  <div class="decoration-wrapper">
    <div class="decoration-left-star position-absolute star"></div>
    <div class="decoration-right-star position-absolute star"></div>
    <img
      v-if="name === '獎項說明'"
      class="position-absolute bottle-decoration"
      src="../assets/images/bottle-decoration.svg"
      alt="decoration"
    />
    <img
      v-if="name === '注意事項'"
      class="position-absolute can-decoration"
      src="../assets/images/aluminum-can.svg"
      alt="decoration"
    />
    <h3 class="flex-center decoration-title">
      <span class="title" v-for="(word, index) in name.split('')" :key="index">
        {{ word }}
        <span class="decoration"></span>
      </span>
    </h3>
  </div>
</template>

<script>
export default {
  name: "DecorationTitle",
  props: {
    name: {
      required: true,
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../assets/css/customAnimation.sass'

.decoration-wrapper
  position: relative
  z-index: 5

  &.normal
    .decoration-title
      .title
        &:before
          background-image: radial-gradient(circle, #ffffff 20%, transparent 10%), radial-gradient(circle, #ffffff 20%, transparent 10%)
    .decoration-left-star
      left: -20%
      top: 0
      width: 65px
      height: 65px
      background-image: url("../assets/images/tw-title-star-left.svg")
      background-size: contain
      background-repeat: no-repeat
      background-position: center

    .decoration-right-star
      right: -30%
      bottom: -50%
      width: 69px
      height: 69px
      background-image: url("../assets/images/tw-title-star-right.svg")
      background-size: contain
      background-repeat: no-repeat
      background-position: center

  .bottle-decoration
    top: 50%
    right: -80%
    transform: translateY(-50%)
    z-index: 10

  .can-decoration
    top: 150%
    left: -80%
    transform: translateY(-50%)
    z-index: 10

  .decoration-title
    color: #ffffff
    padding-top: 32px
    z-index: 3
    position: relative

    .title
      position: relative
      display: inline-block
      width: 60px
      height: 60px
      margin-left: 12px
      margin-right: 12px
      font-size: 60px
      line-height: 60px
      font-family: "DFZongKaiStd", sans-serif
      font-weight: normal

      .decoration
        width: 96px
        height: 96px
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        display: block
        border-radius: 50%

      &:after
        content: ""
        position: absolute
        top: 50%
        left: 50%
        transform: translate3d(-50%, -50%, 0px)
        background-color: #140F6B
        color: #ffffff
        width: 96px
        height: 96px
        line-height: 64px
        border-radius: 50%
        border: 3px solid #ffffff
        box-sizing: border-box
        z-index: -1

      &:before
        content: ""
        position: absolute
        width: 90px
        height: 90px
        top: 100%
        left: 100%
        transform: translate(-50%, -50%)
        border-radius: 50%
        background-color: transparent
        background-image: radial-gradient(circle, #322826 20%, transparent 10%), radial-gradient(circle, #322826 20%, transparent 10%)
        background-size: 6px 6px
        z-index: -100

      &:nth-child(2n+1)
        &:after
          background-color: #dc0011

  @media screen and (max-width: 991.98px)
    &.normal
      .decoration-left-star
        width: 50px
        height: 50px
      .decoration-right-star
        width: 50px
        height: 50px
        right: -25%
        bottom: -30%
    .bottle-decoration
      width: 100px
      right: -60%
    .can-decoration
      left: -60%
      width: 131px
    .decoration-title
      .title
        font-size: 48px
        width: 48px
        height: 48px
        line-height: 48px
        margin-right: 8px
        margin-left: 8px

        .decoration,
        &:after
          width: 70px
          height: 70px

        &:before
          width: 66px
          height: 66px

  @media screen and (max-width: 767.98px)
    .can-decoration
      width: 100px
      left: -30%
  @media screen and (max-width: 575.98px)
    &:not(.normal)
      .decoration-title
        .title
          font-size: 36px
          width: 40px
          height: 40px
          line-height: 40px
          margin-right: 4px
          margin-left: 4px
          .decoration,
          &:after
            width: 60px
            height: 60px
          &:before
            width: 54px
            height: 54px
    .bottle-decoration
      width: 60px
      right: -25%
      top: 0

  @media screen and (max-width: 375.98px)
    .can-decoration
      left: -15%
      width: 70px
</style>
