<template>
  <transition name="fade">
    <div class="mask-wrapper" v-if="visible">
      <div class="mask flex-center">
        <div class="video-wrapper">
          <img
            src="../assets/images/video-mask-yellow.svg"
            alt="想贏的舉手"
            class="
              position-absolute
              decoration decoration-1
              animate__animated animate__bounceIn
              animation_delay_3
            "
          />
          <img
            src="../assets/images/video-mask-green.svg"
            alt="拿金牌了啦"
            class="
              position-absolute
              decoration decoration-2
              animate__animated animate__bounceIn
              animation_delay_4
            "
          />
          <img
            src="../assets/images/video-mask-blue.svg"
            alt="為我們乾一杯"
            class="
              position-absolute
              decoration decoration-3
              animate__animated animate__bounceIn
              animation_delay_1
            "
          />
          <img
            src="../assets/images/video-mask-black.svg"
            alt="喝金牌挺金牌"
            class="
              position-absolute
              decoration decoration-4
              animate__animated animate__bounceIn
              animation_delay_4
            "
          />
          <img
            src="../assets/images/video-mask-red.svg"
            alt="拿金牌挺中華隊"
            class="
              position-absolute
              decoration decoration-5
              animate__animated animate__bounceIn
              animation_delay_2
            "
          />
          <img
            src="../assets/images/video-mask-title.svg"
            alt="video-mask-title"
            class="position-absolute title"
          />
          <!-- <img
            src="../assets/images/video-mask-logo.svg"
            alt="video-mask-title"
            class="position-absolute logo d-md-block d-none"
          /> -->
          <img
            src="../assets/images/logo-mobile.svg"
            alt="video-mask-title"
            class="position-absolute logo"
          />
          <button class="close-button" @click="visible = false">
            <span></span>
            <span></span>
          </button>
          <iframe
            class="video-block"
            src="https://www.youtube.com/embed/rGvcNQJZNL0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from "vue";

export default {
  name: "VideoMask",
  setup() {
    const visible = ref(true);
    return { visible };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/customAnimation";

.mask-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  z-index: 20000;

  .mask {
    height: 100%;
    position: relative;

    .video-wrapper {
      position: relative;
      margin-bottom: 5%;

      .video-block {
        width: 1120px;
        height: 630px;
      }

      .decoration-1 {
        top: -20%;
        left: -6%;
        z-index: -3;
      }

      .decoration-2 {
        top: -11%;
        left: -20%;
        z-index: -2;
      }

      .decoration-3 {
        top: -2%;
        left: -23%;
        z-index: -1;
      }

      .decoration-4 {
        bottom: -10%;
        right: -20%;
        z-index: -1;
      }

      .decoration-5 {
        bottom: -18%;
        right: -10%;
        z-index: -1;
      }

      .title {
        top: -12%;
        right: 0;
      }

      .logo {
        bottom: -20%;
      }

      .close-button {
        position: absolute;
        right: 0;
        top: 0;
        width: 60px;
        height: 60px;
        background-color: #efefef;
        border: none;
        padding: 0;
        overflow: hidden;

        span {
          position: absolute;
          display: block;
          width: 100px;
          height: 2px;
          background-color: #aaaaaa;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);

          &:last-child {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }

      @media screen and (max-width: 1500px) {
        margin: 0;

        .video-block {
          width: 720px;
          height: 405px;
        }

        .decoration-1 {
          top: -30%;
        }

        .decoration-2 {
          top: -18%;
        }

        .decoration-3 {
          top: -5%;
          left: -35%;
        }

        .decoration-4 {
          bottom: -15%;
        }

        .decoration-5 {
          bottom: -28%;
        }

        .title {
          top: -18%;
        }

        .logo {
          bottom: -35%;
        }
      }

      @media screen and (max-width: 1120px) {
        .decoration {
          width: 280px;
        }

        .decoration-1 {
          top: -37%;
          left: 10%;
        }

        .decoration-2 {
          top: -25%;
          left: 0;
        }

        .decoration-3 {
          top: -15%;
          left: -4%;
        }

        .decoration-4 {
          right: -8%;
        }

        .decoration-5 {
          right: 0;
          transform: scale(0.8);
        }

        .title {
          right: -7%;
          transform: scale(0.8);
        }
      }

      @media screen and (max-width: 820px) {
        .video-block {
          width: calc(100vw - 50px);
          height: calc((100vw - 50px) / 1.778);
        }

        .decoration-1 {
          left: -4%;
        }

        .decoration-4 {
          right: -4%;
        }

        .decoration-5 {
          bottom: -25%;
        }

        .title {
          right: -4%;
        }
      }

      @media screen and (max-width: 720px) {
        .video-block {
          margin-bottom: 10vh;
        }

        .close-button {
          top: -40px;
          background-color: inherit;
          width: 32px;
          height: 32px;

          span {
            transition: background-color 0.3s;
            height: 4px;
          }

          &:hover {
            span {
              background-color: #fcd42c;
            }
          }
        }

        .decoration {
          width: 200px;
        }

        .decoration-1 {
          left: 0;
        }
        .decoration-2 {
          left: 10%;
          top: -28%;
        }
        .decoration-3 {
          left: 0;
          top: -20%;
        }

        .decoration-4 {
          bottom: 10%;
          right: 0;
        }

        .decoration-5 {
          right: 10%;
          bottom: 2%;
        }

        .title {
          display: none;
        }

        .logo {
          left: 50%;
          transform: translateX(-50%);
        }
      }

      @media screen and (max-width: 520px) {
        .decoration {
          width: 160px;
        }
        .decoration-1 {
          top: -52%;
        }
        .decoration-2 {
          top: -42%;
        }
        .decoration-3 {
          top: -32%;
        }
        .decoration-4 {
          bottom: 5%;
        }
        .decoration-5 {
          bottom: -4%;
        }

        .logo {
          left: 50%;
          bottom: -65%;
          transform: translateX(-50%) scale(0.8);
        }
      }
    }
  }
}
</style>
