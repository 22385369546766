<template>
  <section class="cross-banner position-relative">
    <div class="marquee position-relative">
      <p class="marquee-word position-absolute">#拿金牌挺中華隊 #想贏的舉手✦</p>
      <p class="marquee-word second position-absolute">
        #拿金牌挺中華隊 #想贏的舉手✦
      </p>
    </div>

    <div class="decoration-image position-absolute">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: "CrossBanner",
};
</script>

<style lang="scss" scoped>
.cross-banner {
  background-color: #0e693d;
  z-index: 3;

  .marquee {
    overflow: hidden;
    height: 100px;
  }

  .marquee-word {
    display: inline-block;
    left: 0;
    top: 0;
    color: #0e693d;
    text-shadow: -1px -1px 0 #ffffff, 0 -1px 0 #ffffff, 1px -1px 0 #ffffff,
      1px 0 0 #ffffff, 1px 1px 0 #ffffff, 0 1px 0 #ffffff, -1px 1px 0 #ffffff,
      -1px 0 0 #ffffff;
    font-size: 100px;
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 1;
    text-align: center;
    transform: translateX(100%);
    animation: scroll-left 20s linear infinite;
    font-family: "DFMingStd", sans-serif;

    &.second {
      left: 100%;
      animation: scroll-left-2 20s linear infinite;
      animation-delay: 10s;
    }
  }

  @keyframes scroll-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @keyframes scroll-left-2 {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-200%);
    }
  }

  &.left {
    .decoration-image {
      right: 60%;
    }

    @media screen and (max-width: 575.98px) {
      margin-top: 20%;
      margin-bottom: 15%;

      .decoration-image {
        right: 56%;
        top: 50%;
        transform: translate(50%, -50%);
      }
    }
  }

  .decoration-image {
    pointer-events: none; // Prevent cover another DOM that make event can't trigger
    top: 50%;
    right: 12%; // Using right to prevent image overflow
    transform: translateY(-50%);
    z-index: 3;
  }

  @media screen and (max-width: 1199.98px) {
    .marquee {
      height: 72px;
    }
    .marquee-word {
      font-size: 72px;
    }
  }

  @media screen and (max-width: 991.98px) {
    .marquee {
      height: 54px;
    }
    .marquee-word {
      font-size: 54px;
    }
  }

  @media screen and (max-width: 767.98px) {
    .marquee {
      height: 33px;
    }
    .marquee-word {
      font-size: 33px;
    }
  }

  @media screen and (max-width: 575.98px) {
    margin-top: 20%;
    margin-bottom: 15%;

    .decoration-image {
      right: 53%;
      top: 50%;
      transform: translate(50%, -50%);
    }
  }
}
</style>
