<template>
  <transition name="fade">
    <div
      v-if="visible"
      class="mobile-navigator position-fixed end-0 top-0 px-3 pt-3"
    >
      <div class="d-flex justify-content-between pt-sm-4">
        <div class="logo-block">
          <img src="../assets/images/logo-mobile.svg" alt="logo" />
        </div>
        <div class="social-button-group d-flex">
          <button class="social-button facebook"></button>
          <button class="social-button instagram"></button>
          <button class="close-button" @click="$emit('close:navigator')">
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
      <ul class="pt-5 mt-5 nav-link">
        <li
          v-for="({ name, toggleLink, active }, index) in navLinkLists"
          :key="index"
        >
          <a
            :href="toggleLink"
            class="mobile-link mb-2 py-2"
            :class="active ? 'active' : ''"
            @click="$emit('close:navigator')"
            >{{ name }}</a
          >
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "MobileNavigator",
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
  },
  emits: ["close:navigator"],
  setup() {
    const navLinkLists = ref([
      { name: "首頁", toggleLink: "#", active: true },
      { name: "活動辦法", toggleLink: "#step", active: false },
      { name: "票選影片", toggleLink: "#instagram", active: false },
      { name: "獎項說明", toggleLink: "#explanation", active: false },
      { name: "注意事項", toggleLink: "#attention", active: false },
    ]);

    const route = useRoute();

    watch(
      () => route.hash,
      () => {
        navLinkLists.value.map((el) => {
          el.active = el.toggleLink === route.hash;
        });
      },
      { immediate: true }
    );

    return { navLinkLists };
  },
};
</script>

<style lang="scss" scoped>
.mobile-navigator {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/loading-background.jpg");
  background-size: cover;
  background-position: center;
  z-index: 1500;

  .logo-info {
    color: #ffffff;
    font-size: 12px;
    margin-top: 8px;
  }

  .social-button-group {
    button {
      margin-right: 8px;
      width: 23px;
      height: 23px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: inherit;
      border: none;
      box-sizing: border-box;
    }

    .facebook {
      background-image: url("../assets/images/facebook-social-icon.svg");
    }

    .instagram {
      background-image: url("../assets/images/instagram-social-icon.svg");
    }

    .close-button {
      background-color: #140f6b;
      padding: 6px;
      position: relative;
      border-radius: 6px;
      border: 1px solid #ffffff;

      span {
        position: absolute;
        display: block;
        height: 2px;
        width: calc(100% - 6px);
        background-color: #ffffff;
        left: 50%;
        top: 50%;

        &:first-child {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:last-child {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .mobile-link {
    color: #ffffff;
    display: block;
    text-align: center;
    text-decoration: none;
    border: 1px solid transparent;
    box-sizing: border-box;
    transition: all 0.3s;

    &:hover,
    &.active {
      border: 1px solid #ffffff;
      background-color: #140f6b;
      border-radius: 20px;
    }
  }

  @media screen and (max-width: 452.98px) {
    background-image: url("../assets/images/mobile-bg.jpg");
  }

  @media screen and (max-width: 320.98px) {
    .nav-link {
      margin: 0 !important;
    }
  }
}
</style>
