<template lang="pug">
transition(name="fade")
  .frame-wrapper
    video(ref="videoEl" autoplay controls :poster="data.image")
      source(:src="data.url" type="video/mp4")
    .frame-btn-close(@click="$emit('close')") X
      
</template>

<script>
export default {
  name: "VideoMask",
  data: ()=>{
    return {
      visible: false
    }
  },
  props: {
    data: {
      type: Object
    }
  },
  mounted(){
    const vid = this.$refs.videoEl
    vid.onpause = ()=>{
      // console.log("The video has been paused");
      this.$emit('close')
    };
  }
};
</script>

<style lang="sass" scoped>
@import "../assets/css/customAnimation"

.frame-wrapper
  position: fixed
  width: 100vw
  height: 100vh
  left: 0
  top: 0
  background-color: rgba(0,0,0,0.95)
  z-index: 9999
  display: flex
  align-items: center
  justify-content: center
  // video
  //   height: 100%
  .frame-btn-close
    font-size: 2rem
    position: absolute
    right: 20px
    top: 10px
    color: white
    cursor: pointer
  &.not-desktop
    video
      width: 0
      height: 0
    .frame-btn-close
      display: none



</style>
