<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="211.978"
    height="397.973"
    viewBox="0 0 211.978 397.973"
    id="home-decoration-thunder"
  >
    <g
      class="parallax-layer"
      data-depth="0.008"
      data-name="Component 13 – 29"
      opacity=".799"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    >
      <path
        data-name="Path 7463"
        fill="#fff16c"
        d="M1 190.757l87.551 24.4a7.208 7.208 0 0 1 4.444 3.558 6.856 6.856 0 0 1 .345 5.6L29.105 396.973l173.794-198.355-87.436-24.371a7.182 7.182 0 0 1-4.329-3.347 6.843 6.843 0 0 1-.618-5.358l45.738-155.097z"
      ></path>
      <path
        data-name="Path 7464"
        fill="#fff16c"
        d="M8.519 181.823l87.794 24.468a7.228 7.228 0 0 1 4.457 3.568 6.876 6.876 0 0 1 .346 5.613L36.702 388.624l174.275-198.917-87.679-24.44a7.2 7.2 0 0 1-4.341-3.356 6.863 6.863 0 0 1-.62-5.373L164.203 1z"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "HomeDecorationFirework",
};
</script>

<style scoped lang="scss">
#home-decoration-thunder {
  &.purple {
    path {
      fill: #dc06c3;
    }
  }
}
</style>
