<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="427"
    height="880"
    viewBox="0 0 427 880"
    :class="'firework_' + index"
    id="home-decoration-firework"
  >
    <defs>
      <clipPath id="prefix__a">
        <path
          data-name="Rectangle 215"
          fill="#fff"
          stroke="#fff"
          stroke-width="10"
          stroke-dasharray="0 33"
          d="M0 0h427v880H0z"
        ></path>
      </clipPath>
    </defs>
    <g data-name="Mask Group 31" clip-path="url(#prefix__a)">
      <g data-name="Group 293" transform="translate(-401.97 39.3)">
        <g
          data-name="light"
          stroke-linecap="round"
          stroke-width="10"
          stroke-dasharray="0 33"
        >
          <path
            data-name="Path 7579"
            d="M384.485 386.603L464.864 8.448"
            fill="#ff48ea"
            stroke="#dc06c3"
          ></path>
          <path
            data-name="Line 84"
            fill="none"
            stroke="#fff16c"
            stroke-linejoin="round"
            d="M384.485 386.602L541.731 33.423"
          ></path>
          <path
            data-name="Line 85"
            fill="none"
            stroke="#dc06c3"
            stroke-linejoin="round"
            d="M384.485 386.603L611.724 73.835"
          ></path>
          <path
            data-name="Line 86"
            fill="none"
            stroke="#fff16c"
            stroke-linejoin="round"
            d="M384.485 386.603l287.302-258.688"
          ></path>
          <path
            data-name="Line 87"
            fill="none"
            stroke="#f519d3"
            stroke-linejoin="round"
            d="M384.485 386.602l334.808-193.301"
          ></path>
          <path
            data-name="Line 88"
            fill="none"
            stroke="#fff16c"
            stroke-linejoin="round"
            d="M384.485 386.603l367.681-119.467"
          ></path>
          <path
            data-name="Line 89"
            fill="none"
            stroke="#dc06c3"
            stroke-linejoin="round"
            d="M384.485 386.603l384.485-40.411"
          ></path>
          <path
            data-name="Line 90"
            fill="none"
            stroke="#fff16c"
            stroke-linejoin="round"
            d="M384.485 386.603l384.485 40.411"
          ></path>
          <path
            data-name="Line 91"
            fill="none"
            stroke="#dc06c3"
            stroke-linejoin="round"
            d="M384.485 386.603L752.166 506.07"
          ></path>
          <path
            data-name="Line 92"
            fill="none"
            stroke="#fff16c"
            stroke-linejoin="round"
            d="M384.485 386.603l334.808 193.301"
          ></path>
          <path
            data-name="Line 93"
            fill="none"
            stroke="#dc06c3"
            stroke-linejoin="round"
            d="M384.485 386.603l287.302 258.688"
          ></path>
          <path
            data-name="Line 94"
            fill="none"
            stroke="#fff16c"
            stroke-linejoin="round"
            d="M384.485 386.603l227.239 312.768"
          ></path>
          <path
            data-name="Line 95"
            fill="none"
            stroke="#dc06c3"
            stroke-linejoin="round"
            d="M384.485 386.603l157.246 353.179"
          ></path>
          <path
            data-name="Line 96"
            fill="none"
            stroke="#fff16c"
            stroke-linejoin="round"
            d="M384.485 386.603l80.379 378.155"
          ></path>
        </g>
        <circle
          data-name="Ellipse 21"
          cx="88"
          cy="88"
          r="88"
          transform="translate(296.97 298.7)"
          fill="#0e693d"
        ></circle>
      </g>
    </g>
  </svg>
</template>

<script>
import { playLightAnimation } from "@/assets/animations/gsapAnimation";

export default {
  name: "HomeDecorationFirework",
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    playLightAnimation(`firework_${this.index}`);
  },
};
</script>

<style scoped lang="scss">
#home-decoration-firework {
  path {
    opacity: 0.2;
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 0 500;
      stroke-opacity: 0;
    }
    50% {
      stroke-opacity: 1;
    }
    80% {
      stroke-opacity: 0.8;
    }
    100% {
      stroke-dasharray: 0 33;
      stroke-dashoffset: 60;
      stroke-opacity: 0;
    }
  }
}
</style>
