<template>
  <div class="page-observer" :class="status" ref="root"></div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "PageObserver",
  props: {
    status: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const root = ref(null); // using ref to replace this.$el in vue3
    const observer = ref(null);
    const router = useRouter();

    onMounted(() => {
      observer.value = new IntersectionObserver(([entry]) => {
        if (entry && entry.isIntersecting) {
          router.push({ name: "Home", hash: `#${props.status}` });
        }
      });
      observer.value.observe(root.value);
    });

    onBeforeUnmount(() => {
      observer.value.unobserve(root.value);
    });

    return { root };
  },
};
</script>

<style lang="scss" scoped>
.page-observer {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  pointer-events: none;

  &.attention {
    top: 0;
  }
}
</style>
