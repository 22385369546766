<template>
  <div class="instagram-thumbnail">
    <div class="content">
      <div class="avatar-block">
        <img :src="data.avatar" alt="avatar" />
        <p class="text-overflow">{{ data.name }}</p>
      </div>

      <div class="image-wrapper position-relative" @click="playVid">
        <img :src="data.image" alt="instagram-image" />
      </div>

      <div class="vote-block">
        <button class="vote-button" @click="vote">投他一票</button>

        <div class="vote-number-block">
          <span class="vote-number text-overflow">{{ adjustTickets }}</span>
          <span class="unit">票</span>
        </div>
      </div>
      <!-- <span>{{data.date}}</span> -->
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "InstagramThumbnail",
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const adjustTickets = computed(() => {
      if ("tickets" in props.data) {
        return props.data.tickets.toLocaleString("en-US");
      }
      return NaN;
    });

    return { adjustTickets };
  },
  methods: {
    playVid(){
      let data = {
        id: this.data.id,
        url: this.data.video,
        image: this.data.image
      }
      this.$emit('playVideo', data)
    },
    vote(){
      this.$emit('vote', this.data.id)
    }
  }
};
</script>

<style lang="scss" scoped>
.instagram-thumbnail {
  flex: 1;
  min-width: 0; // Fix text-overflow not working's situation
  background-color: #fcd42c;
  border-radius: 10px;
  margin: 12px;
  border: 2px solid #707070;
  box-sizing: border-box;
  z-index: 1;

  // hot
  &.hot {
    position: relative;
    margin: 0;

    &.ig-top-0 {
      &:after {
        background-image: url("../assets/images/video-badge-1.svg");
        content: "第一名";
      }
    }

    &.ig-top-1 {
      &:after {
        background-image: url("../assets/images/video-badge-2.svg");
        content: "第二名";
      }
    }

    &.ig-top-2 {
      &:after {
        background-image: url("../assets/images/video-badge-3.svg");
        content: "第三名";
      }
    }

    &:after {
      position: absolute;
      right: 0;
      top: -18%;
      background-image: url("../assets/images/video-badge-1.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 97px;
      height: 97px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
    }
  }

  // swiper
  &.ig-swiper {
    background-color: #1279c4;
    margin-bottom: 60px;

    .content {
      .avatar-block {
        color: #ffffff;
      }
      .vote-block {
        background-image: linear-gradient(
            rgba(#ffffff, 0.3) 1px,
            transparent 1px
          ),
          linear-gradient(90deg, rgba(#ffffff, 0.3) 1px, transparent 1px);
        background-size: 12px 12px;

        .vote-number-block {
          color: #ffffff;
        }
      }
    }
  }

  .content {
    .avatar-block {
      padding: 8px 12px;
      display: flex;
      align-items: center;

      img {
        width: 80px;
        height: 80px;
        background-color: #cccccc;
        border-radius: 50%;
        border: 2px solid #ffffff;
        margin-right: 12px;
      }

      p {
        margin: 0;
        font-size: 35px;
        font-weight: bold;
      }
    }

    .image-wrapper {
      padding-bottom: 54%;
      cursor: pointer;
      overflow: hidden;


      img {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 100%;
        object-fit: cover;
        border-bottom: 2px solid #707070;
        border-top: 2px solid #707070;
        transition: 0.3s;
      }
      &:hover img{
        transform: scale(1.1);
        transition: 0.5s;
      }
    }

    .vote-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      background-image: linear-gradient(rgba(#000000, 0.1) 1px, transparent 1px),
        linear-gradient(90deg, rgba(#000000, 0.1) 1px, transparent 1px);
      background-size: 12px 12px;

      .vote-button {
        padding: 4px 16px;
        border: 1px solid #000000;
        border-radius: 30px;
        font-size: 20px;
        font-weight: 500;
        font-family: "Noto Sans TC", sans-serif;
        background-color: #ffffff;
        transition: all 0.3s;

        &:hover {
          background-color: #140f6b;
          color: #ffffff;
          border-color: #ffffff;
        }
      }

      .vote-number-block {
        flex-basis: 60%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 0; // Fix text-overflow not working's situation

        .vote-number {
          font-size: 50px;
          font-style: italic;
          text-align: right;
          font-weight: bold;
          padding-right: 4px;
        }

        .unit {
          margin-left: 12px;
          font-size: 20px;
          font-family: "Noto Sans TC", sans-serif;
        }
      }
    }
  }

  @media screen and (max-width: 1199.98px) {
    &.hot {
      &:after {
        top: -26%;
      }
    }
    .content {
      .avatar-block {
        img {
          width: 60px;
          height: 60px;
        }
        p {
          font-size: 24px;
        }
      }

      .vote-block {
        .vote-button {
          font-size: 16px;
          padding: 4px 14px;
        }
        .vote-number-block {
          .vote-number {
            font-size: 24px;
          }
          .unit {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 991.98px) {
    &.hot {
      margin-top: 10%;
      &:after {
        top: -30%;
        transform: scale(0.6);
        transform-origin: right center;
      }
    }
    .content {
      .avatar-block {
        padding: 4px 6px;
        img {
          width: 50px;
          height: 50px;
        }
        p {
          font-size: 16px;
        }
      }
      .vote-block {
        padding: 6px;
        .vote-button {
          font-size: 14px;
          padding: 4px 6px;
        }
        .vote-number-block {
          .vote-number {
            font-size: 16px;
          }
          .unit {
            margin-top: 8px;
            font-size: 12px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767.98px) {
    .content {
      .vote-block {
        padding: 6px 2px;
        .vote-button {
          padding: 4px;
          transform: scale(0.8);
          transform-origin: left center;
        }
        .vote-number-block {
          flex-basis: 50%;
          .unit {
            margin-left: 0;
            margin-top: 8px;
            font-size: 12px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 575.98px) {
    margin: 8px 24px;
    &.hot {
      margin: 0 24px;
      &:after {
        transform: scale(0.8);
        top: -16%;
      }
    }
    .content {
      .avatar-block {
        padding: 8px 12px;
        img {
          width: 64px;
          height: 64px;
        }
        p {
          font-size: 20px;
        }
      }
      .vote-block {
        padding: 8px 12px;
        .vote-button {
          padding: 4px 16px;
          transform: scale(1);
        }
        .vote-number-block {
          flex-basis: 60%;
          .vote-number {
            font-size: 40px;
          }
          .unit {
            margin-left: 8px;
            font-size: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 461.98px) {
    &.hot {
      &:after {
        top: -20%;
      }
    }
  }

  @media screen and (max-width: 375.98px) {
    &.hot {
      &:after {
        transform: scale(0.6);
        transform-origin: right center;
      }
    }
    .content {
      .vote-block {
        .vote-button {
          padding: 4px 8px;
        }
        .vote-number-block {
          .vote-number {
            font-size: 20px;
          }
          .unit {
            font-size: 16px;
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>
