<template>
  <h1 class="tw-title flex-center my-5">
    <span class="svg-wrapper position-relative d-block">
      <span class="decoration-star-1 position-absolute"></span>
      <span class="decoration-star-2 position-absolute"></span>
      <img
        class="decoration-taiwan position-absolute d-xl-block d-none"
        src="../assets/images/tw-taiwan-word.svg"
        alt="taiwan"
      />
      <img
        class="decoration-beer position-absolute d-xl-block d-none"
        src="../assets/images/tw-beer-word.svg"
        alt="beer"
      />
      <img src="../assets/images/tw-title.svg" alt="tw-title" class="title" />
      <span class="decoration-word position-absolute d-flex d-xl-none">
        <img src="../assets/images/tw-taiwan-word.svg" alt="taiwan" />
        <img src="../assets/images/tw-beer-word.svg" alt="beer" />
      </span>
    </span>
  </h1>
</template>

<script>
export default {
  name: "TwTitle",
};
</script>

<style scoped lang="scss">
.tw-title {
  pointer-events: none !important;

  .decoration-star-1 {
    left: -40px;
    top: 40%;
    transform: translateY(-50%);
    width: 73px;
    height: 73px;
    background-image: url("../assets/images/tw-title-star-left.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .decoration-star-2 {
    right: -35px;
    bottom: -50px;
    width: 52px;
    height: 69px;
    background-image: url("../assets/images/tw-title-star-right.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .decoration-taiwan {
    left: -200px;
    bottom: 20px;
  }

  .decoration-beer {
    right: -200px;
    bottom: 20px;
  }

  @media (max-width: 1440px) and (max-height: 900px) {
    transform: scale(0.8);
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: 1200px) {
    .decoration-word {
      bottom: -10%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media screen and (max-width: 1060px) {
    .svg-wrapper {
      padding-top: 5%;
    }
  }

  @media screen and (max-width: 991.98px) {
    margin-top: 5% !important;
    transform: scale(0.7);
    .decoration-star-1 {
      width: 48px;
      height: 48px;
      left: -5%;
      top: 65%;
    }
    .decoration-star-2 {
      bottom: 0;
      width: 36px;
      height: 48px;
    }
  }

  @media screen and (max-width: 767.98px) {
    margin-top: 20% !important;
    .title {
      width: 100%;
    }
  }

  @media screen and (max-width: 575.98px) {
    transform: scale(1);

    .title {
      padding: 0 6%;
    }

    .svg-wrapper {
      max-width: 500px;
      padding-top: 18%;
    }
    .decoration-word {
      bottom: -20%;
      left: 50%;
      transform: translateX(-50%) scale(0.6);
    }
  }

  @media screen and (max-width: 375.98px) {
    margin-top: 30% !important;
  }
}
</style>
